import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Root from "./pages/Root";
import Error from "./pages/Error";
import HomePage from "./pages/HomePage";
import Work from "./pages/Work";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <Error />,
    children: [
      {path: '', element: <HomePage />},
      {path: 'work/eng', element: <Work language={'eng'} />},
      {path: 'work/ger', element: <Work language={'ger'} />},
    ]
  }
])

function App() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
