export const german = {
  slogan: ["Ihre Lösung für", "präzise und qualitativ hochwertige", "mechanische Teile"],
  offer: "Offerte anfragen",
  page_2_text: [
    "Als zuverlässiger Hersteller von mechanischen Teilen mit Sitz in der Schweiz und Partnern in Südosteuropa legen wir bei SNM-Solution höchsten Wert auf Schweizer Qualität und Kundenzufriedenheit.",
    "Unser globales Netzwerk von Fertigungsstätten ermöglicht es uns, hochwertige Teile zu wettbewerbsfähigen Preisen anzubieten.",
    "Unsere Erfahrung in der Branche hat uns gelehrt, wie wichtig es ist, eng mit unseren Kunden zusammenzuarbeiten, um ihre spezifischen Bedürfnisse zu verstehen und die besten Lösungen zu entwickeln.",
    "Als Schweizer Unternehmen sind wir uns der hohen Standards bewusst, die von unseren Kunden erwartet werden.",
    "Deshalb setzen wir auf modernste Fertigungstechnologien und ein strenges Qualitätsmanagement. Wir stellen sicher, dass jedes Teil, dass unser Haus verlässt, den höchsten Standards entspricht.",
    "Kontaktieren Sie uns noch heute, um zu erfahren, wie wir zusammen Ihre Produktion optimieren können."
  ],
  services_text: ["CNC-Drehen (5-Achsen)", "Erodieren", "Oberflächenbehandlungen", "CNC-Fräsen (5-Achsen)", "CNC-Schleifen", "Qualitätsnachweis"],
  reviews_text: [
    "Wählen Sie SNM-Solution", "Ansprechpartner",
    "Bei der Auslagerung Ihrer Produktion sind wir mit unserem breiten Netzwerk an Partnern und Erfahrung der zuverlässige Ansprechpartner für Ihre mechanische Fertigung.",
    "Preis-Leistung",
    "Unser Ziel ist es, Ihnen Planungssicherheit zu bieten und Kosten zu sparen. Von Einzelteilen bis zur Serienproduktion sind wir für Sie da. Wir überwachen die Produktion, stellen die Qualität sicher und liefern alle Bauteile direkt ab unserem Standort in der Ostschweiz.",
    "Partner",
    "Dank unserer Partner, die mit CNC-Maschinen der neuesten Generation ausgestattet sind, können wir Ihnen höchste Präzision in der mechanischen Produktion garantieren und uns gleichzeitig flexibel auf die Erfüllung Ihrer Aufträge einstellen.",
    "Qualitätsnachweis",
    "Wir setzen modernste Messtechnologien ein, um höchste Qualitätsstandards zu gewährleisten. Dabei legen wir kritische Funktions- und Prüfmasse gemäß Ihren individuellen Anforderungen fest und dokumentieren diese in einem Prüfprotokoll."
  ],
  work_text: ["Wir bieten eine einfache Upload-Funktion für Ihre Anfrage an. Falls Ihre Zeichnung nicht alle erforderlichen Informationen enthält oder Sie bestimmte Anforderungen haben, die hervorgehoben werden sollten, können Sie diese unter \"Bemerkungen\" angeben. Bitte wählen Sie auch die Stückzahl aus, damit wir Ihnen das beste Angebot machen können. Wenn wir weitere Fragen haben, werden wir uns umgehend mit Ihnen in Verbindung setzen."],
  send: "Senden!",
  navigation: ["Über SNM Solution", "Dienstleistungen", "Unternehmen", "Offerte anfragen", "Kontakt"],
  about_us_heading: ["ÜBER UNS", "ZUVERLÄSSIGKEIT", "UND PRÄZISION"],
  products_heading: ["DIENSTLEISTUNGEN", "UNSERE", "SERVICES"],
  reviews_heading: ["UNTERNEHMEN"],
  contact_heading: ["KONTAKT", "KONTAKTIEREN", "SIE UNS"],
  email_text: ["Email senden", "Email senden"],
  contact_info: ["Telefon", "Adresse", "©2023 SNM Solution – Alle Rechte vorbehalten."],
  work_heading: ["OFFERTE", "ANFRAGEN", "Unverbindliche Offerte anfordern"],
  input_fields: ["Zeichnungsnummer / Bezeichnung", "Dateien hochladen (2D,3D,PDF,ZIP)", "Liefertermin", "Stückzahl", "Bemerkungen", "Zielpreis pro Stück", "Name", "Ihre Referenz", "E-mail", "Firma",
    "Adresse", "PLZ und Ort", "Telefon"],
  mail: ['Erfolgreich!', 'Fehler!', 'Senden Sie noch eine Mail', 'Gehen Sie zurück zur Homepage']
}

export const english = {
  slogan: ["YOUR SOLUTION FOR", "HIGH-QUALITY", "MECHANICAL PARTS"],
  offer: "Request offer",
  page_2_text: [
    "As a reliable manufacturer of mechanical parts based in Switzerland with partners in Southeast Europe, at SNM-Solution we place the highest value on Swiss quality and customer satisfaction.",
    "Our global network of production facilities enables us to offer high-quality parts at competitive prices.",
    "Our experience in the industry has taught us the importance of working closely with our customers to understand their specific needs and develop the best solutions.",
    "As a Swiss company, we are aware of the high standards expected by our customers.",
    "That is why we rely on state-of-the-art manufacturing technologies and strict quality management to ensure that every part leaving our facility meets the highest standards.",
    "Contact us today to learn how we can work together to optimize your production."],
  services_text: ["CNC TURNING", "ERODING", "SURFACE TREATMENT", "CNC MILLING", "CNC GRINDING", "3D-MEASUREMENTS"],
  reviews_text: [
    "CHOOSE SNM-SOLUTION", "Point of Contact",
    "As a reliable point of contact for your mechanical production, we offer outsourcing solutions through our extensive network of partners and experience.",
    "Price-performance ratio",
    "Our goal is to provide you with planning reliability and cost savings. From individual parts to series production, we are here for you. We monitor production, ensure quality, and deliver all components directly from our location in Eastern Switzerland.",
    "Partner",
    "Thanks to our partners equipped with the latest generation CNC machines, we can guarantee you the highest precision in mechanical production while remaining flexible in fulfilling your orders.",
    "Proof of quality",
    "We use state-of-the-art measuring technologies to ensure the highest quality standards. We determine critical functional and testing dimensions according to your individual requirements and document them in a test protocol."
  ],
  work_text: ["We offer a simple upload function for your request. If your drawing does not contain all the required information or you have specific requirements that should be highlighted, you can indicate them under \"Remarks\". Please also select the quantity so that we can make you the best offer. If we have further questions, we will contact you immediately."],
  send: "Send to Us!",
  navigation: ["About Us", "Products and services", "References", "Request offer", "Contact"],
  about_us_heading: ["ABOUT US", "RELIABILITY", "AND PRECISION"],
  products_heading: ["PRODUCTS", "OUR", "SERVICES"],
  reviews_heading: ["REVIEWS"],
  contact_heading: ["CONTACT", "CONTACT", "SNM SOLUTION"],
  email_text: ["Enter E-mail", "Send your draft"],
  contact_info: ["Number", "Address", "No copyright allowed, all rights belong to Us"],
  work_heading: ["OFFER", "REQUEST", "Request a free offer now"],
  input_fields: ["Drawing number / designation", "Upload files (2D,3D,PDF,ZIP)", "Desired delivery date", "Number of pieces", "Remarks", "Target price per part", "Name", "Your reference", "E-mail", "Company name",
    "Address", "Postcode & City", "Phone number"],
  mail: ['Success!', 'Error!', 'Send another mail', 'Go back to the homepage']
}