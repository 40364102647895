import classes from './MainNavigation.module.css';
import {english, german} from "../../pages/languages";
import {NavLink} from "react-router-dom";

function MainNavigation({language}) {
  const languageTekst = language === 'ger' ? german : english;

  return (
    <aside className={classes.aside}>
      <div className={classes.logo}></div>
      <nav className={classes.navigation}>
        <a href={'#about-us-section'}>{languageTekst.navigation[0]}</a>
        <a href={'#products'}>{languageTekst.navigation[1]}</a>
        <a href={'#references'}>{languageTekst.navigation[2]}</a>
        <NavLink to={`work/${language}`}>{languageTekst.navigation[3]}</NavLink>
        <a href={'#contact'}>{languageTekst.navigation[4]}</a>
      </nav>
    </aside>
  );
}

export default MainNavigation;
