import classes from './MainNavigation.module.css';
import {english, german} from "../../pages/languages";
import React from "react";
import {NavLink} from "react-router-dom";

const PhoneNavigation = ({language, isSeen, setIsSeen, setLanguage, setLanguageTekst}) => {
  const languageTekst = language === 'ger' ? german : english;

  return (
    <div className={`${classes.phone} ${isSeen ? classes.phone_alt : ''}`}>
      <div className={`${classes.close}`} onClick={() => setIsSeen(false)}>
        <i className='bx bx-x-circle'></i>
      </div>
      <nav className={classes.phone_navigation}>
        <a href={'#about-us-section'} onClick={() => setIsSeen(false)}>{languageTekst.navigation[0]}</a>
        <a href={'#products'} onClick={() => setIsSeen(false)}>{languageTekst.navigation[1]}</a>
        <a href={'#references'} onClick={() => setIsSeen(false)}>{languageTekst.navigation[2]}</a>
        <NavLink to={`work/${language}`} onClick={() => setIsSeen(false)}>{languageTekst.navigation[3]}</NavLink>
        <a href={'#contact'} onClick={() => setIsSeen(false)}>{languageTekst.navigation[4]}</a>
      </nav>

      <div className={`${classes.language}`}>
        <div className={`${language === 'ger' ? classes.chosen : ''}`} onClick={() => {
          setLanguage('ger');
          setLanguageTekst(german);
        }}>DEU</div>

        <div className={`${language === 'eng' ? classes.chosen : ''}`} onClick={() => {
          setLanguage('eng');
          setLanguageTekst(english);
        }}>ENG</div>
      </div>
    </div>
  );
};

export default PhoneNavigation;