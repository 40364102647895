import React, {useEffect, useMemo, useRef, useState} from 'react';
import classes from "./HomePage.module.css";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import ReactPlayer from 'react-player';
import {NavLink} from "react-router-dom";
import MainNavigation from "../components/UI/MainNavigation";
import {german, english} from './languages';
import PhoneNavigation from "../components/UI/PhoneNavigation";


function Map() {
  const center = useMemo(() => ({ lat: 47.411281897464754, lng: 9.625306512410598 }), []);
  return (
    <GoogleMap zoom={13} center={center} mapContainerClassName={classes.map_container} >
      <Marker position={center} />
    </GoogleMap>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const HomePage = () => {
  const video = useRef();
  const [playVideo, setPlayVideo] = useState(null);
  const [language, setLanguage] = useState('ger');
  const [languageTekst, setLanguageTekst] = useState(german);

  const [phoneMenuSeen, setPhoneMenuSeen] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API,
  });

  const isIntersecting = useIsInViewport(video);
  if (isIntersecting && playVideo === null) setPlayVideo(true);
  return (
    <>
      <div className={classes.phone_menu} onClick={() => setPhoneMenuSeen(true)}>
        <i className='bx bx-menu'></i>
      </div>

      <MainNavigation language={language} />

      <PhoneNavigation language={language} isSeen={phoneMenuSeen} setIsSeen={setPhoneMenuSeen} setLanguage={setLanguage} setLanguageTekst={setLanguageTekst} />

      <section className={classes.section_one}>
        <div className={classes.background}></div>
        <div className={classes.gradient}></div>

        <div className={`${classes.numbers} ${classes.one}`}>01</div>
        <div className={`${classes.lines} ${classes.line_one}`}></div>

        <div className={`${classes.language}`}>
          <div className={`${language === 'ger' ? classes.chosen : ''}`} onClick={() => {
            setLanguage('ger');
            setLanguageTekst(german);
          }}>DEU</div>

          <div className={`${language === 'eng' ? classes.chosen : ''}`} onClick={() => {
            setLanguage('eng');
            setLanguageTekst(english);
          }}>ENG</div>
        </div>

        <div className={classes.page_one_text}>
          <div>
            <h1 className={classes.h1_title}>SNM<br />SOLUTION</h1>
            <h2 className={classes.h3_title}>{languageTekst.slogan[0]}<br /><strong>{languageTekst.slogan[1]}</strong><br />{languageTekst.slogan[2]}</h2>
          </div>

          <NavLink to={`work/${language}`}>
            <div className={classes.request_offer_button}>
              {languageTekst.offer}
              <i className='bx bx-chevron-right'></i>
            </div>
          </NavLink>
        </div>
      </section>

      <section className={classes.section_two}>
        <div id={'about-us-section'} className={classes.h4_title}>{languageTekst.about_us_heading[0]}</div>

        <p className={`${classes.numbers} ${classes.two}`}>02</p>
        <div className={`${classes.lines} ${classes.line_two}`}></div>
        <div className={`${classes.lines} ${classes.line_three}`}></div>

        <div className={classes.h2_title}>
          <h2>{languageTekst.about_us_heading[1]}<br />{languageTekst.about_us_heading[2]}</h2>
        </div>

        <div className={classes.page_two_text}>
          <div>
            <p>{languageTekst.page_2_text[0]}
              <br />
              <br />
              {languageTekst.page_2_text[1]}
              <br />
              <br />
              {languageTekst.page_2_text[2]}
              <br />
              <br />
            </p>
            <p>
              {languageTekst.page_2_text[3]}
              <br />
              <br />
              {languageTekst.page_2_text[4]}
              <br />
              <br />
              {languageTekst.page_2_text[5]}
            </p>
          </div>

          <div className={classes.page_two_image} ref={video}>
            <ReactPlayer url={'./images/pexels-daniel-smyth-8926577-3840x2160-24fps.mp4'} playing={playVideo} onPause={() => setPlayVideo(false)}
                         controls={true} width={"100%"} height={"100%"} />
          </div>
        </div>
      </section>

      <section className={classes.section_three}>
        <div id={'products'} className={`${classes.h4_title} ${classes.black_color}`}>{languageTekst.products_heading[0]}</div>

        <p className={`${classes.numbers} ${classes.three}`}>03</p>
        <div className={`${classes.lines} ${classes.line_6}`}></div>
        <div className={`${classes.lines} ${classes.line_4}`}></div>
        <div className={`${classes.lines} ${classes.line_5}`}></div>

        <div className={classes.h2_title}>
          <h2 className={classes.black_color}>{languageTekst.products_heading[1]}<br />{languageTekst.products_heading[2]}</h2>
        </div>

        <div className={classes.page_three_icons}>
          <div>
            <div>
              <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.32.jpeg'} alt={languageTekst.services_text[0]}/>
              <div>
                <div></div>
                <p>{languageTekst.services_text[0]}</p>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.33.jpeg'} alt={languageTekst.services_text[1]}/>
              <div className={classes.additional}>
                <i className='bx bx-plus' onClick={ev => {
                  ev.target['parentElement']['parentElement'].classList.toggle(`${classes.expand}`)
                  ev.target['classList'].toggle('bx-minus');
                  ev.target['classList'].toggle('bx-plus');
                }}></i>
                <p>{languageTekst.services_text[1]}</p>
                <div>
                  <div>
                    <p>-</p>
                    <p>Senkerodieren</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>Drahterodieren</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.32(1).jpeg'} alt={languageTekst.services_text[2]}/>
              <div className={classes.additional}>
                <i className='bx bx-plus' onClick={ev => {
                  ev.target['parentElement']['parentElement'].classList.toggle(`${classes.expand_3rd}`)
                  ev.target['classList'].toggle('bx-minus');
                  ev.target['classList'].toggle('bx-plus');
                }}></i>
                <p>{languageTekst.services_text[2]}</p>
                <div>
                  <div>
                    <p>-</p>
                    <p>Eloxieren</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>Härten</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>Karbonietrieren</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>Laserbeschriften</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>etc.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img src={'./images/pexels-daniel-smyth-10406128.jpg'} alt={languageTekst.services_text[3]}/>
              <div>
                <div></div>
                <p>{languageTekst.services_text[3]}</p>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.32(2).jpeg'} alt={languageTekst.services_text[4]}/>
              <div className={classes.additional}>
                <i className='bx bx-plus' onClick={ev => {
                  ev.target['parentElement']['parentElement'].classList.toggle(`${classes.expand_2nd}`)
                  ev.target['classList'].toggle('bx-minus');
                  ev.target['classList'].toggle('bx-plus');
                }}></i>
                <p>{languageTekst.services_text[4]}</p>
                <div>
                  <div>
                    <p>-</p>
                    <p>CNC Flachschleifen</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>CNC Profilschleifen</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>CNC Rundschleifen</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.32(3).jpeg'} alt={languageTekst.services_text[5]}/>
              <div className={classes.additional}>
                <i className='bx bx-plus' onClick={ev => {
                  ev.target['parentElement']['parentElement'].classList.toggle(`${classes.expand}`)
                  ev.target['classList'].toggle('bx-minus');
                  ev.target['classList'].toggle('bx-plus');
                }}></i>
                <p>{languageTekst.services_text[5]}</p>
                <div>
                  <div>
                    <p>-</p>
                    <p>Massprotokoll</p>
                  </div>
                  <div>
                    <p>-</p>
                    <p>Form und Lagemessungen</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<section className={`${classes.section_4} display-none`}>*/}
      {/*  <div id={'reviews'} className={classes.h4_title}>REFERENCES</div>*/}

      {/*  <p className={`${classes.numbers} ${classes.two}`}>04</p>*/}
      {/*  <div className={`${classes.lines} ${classes.line_two}`}></div>*/}
      {/*  <div className={`${classes.lines} ${classes.line_three}`}></div>*/}
      {/*  <div className={`${classes.lines} ${classes.line_6}`}></div>*/}

      {/*  <div className={`${classes.h2_title} ${classes.h2_title_alt}`}>*/}
      {/*    <h2>OUR<br />PROJECTS</h2>*/}

      {/*    <div className={classes.references_list}>*/}
      {/*      <i className='bx bx-chevron-left'></i>*/}
      {/*      <i className='bx bx-chevron-right'></i>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className={`${classes.page_4_icons} ${classes.page_three_icons}`}>*/}
      {/*    <div>*/}
      {/*      <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.32.jpeg'} alt={'first photo'}/>*/}
      {/*      <div>*/}
      {/*        <p>CNC TURNING</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div>*/}
      {/*      <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.33.jpeg'} alt={'first photo'}/>*/}
      {/*      <div>*/}
      {/*        <p>CNC TURNING</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div>*/}
      {/*      <img src={'./images/WhatsApp Image 2023-04-26 at 16.47.32(1).jpeg'} alt={'first photo'}/>*/}
      {/*      <div>*/}
      {/*        <p>ERODING</p>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <section className={classes.section_4}>
        <div id={'references'} className={`${classes.h4_title} ${classes.blue_color}`}>{languageTekst.reviews_heading[0]}</div>

        <p className={`${classes.numbers} ${classes.three}`}>04</p>
        <div className={`${classes.lines} ${classes.line_4}`}></div>
        <div className={`${classes.lines} ${classes.line_5}`}></div>
        <div className={`${classes.lines} ${classes.line_6}`}></div>

        <div className={`${classes.h2_title} ${classes.h2_title_mid}`}>
          <h2 className={classes.blue_color}>{languageTekst.reviews_text[0]}</h2>
        </div>

        <div className={classes.reviews_text}>
          <div>
            <h3>{languageTekst.reviews_text[1]}</h3>
            <p>{languageTekst.reviews_text[2]}</p>
          </div>

          <div>
            <h3>{languageTekst.reviews_text[3]}</h3>
            <p>{languageTekst.reviews_text[4]}</p>
          </div>

          <div>
            <h3>{languageTekst.reviews_text[5]}</h3>
            <p>{languageTekst.reviews_text[6]}</p>
          </div>

          <div>
            <h3>{languageTekst.reviews_text[7]}</h3>
            <p>{languageTekst.reviews_text[8]}</p>
          </div>
        </div>
        <div className={classes.mountains}></div>
        <div className={classes.gradient_2}></div>
      </section>

      <section className={classes.section_5}>
        <a id={'contact'} className={`${classes.h4_title} ${classes.blue_color}`}>{languageTekst.contact_heading[0]}</a>

        <p className={`${classes.numbers} ${classes.three}`}>05</p>
        <div className={`${classes.lines} ${classes.line_8}`}></div>
        <div className={`${classes.lines} ${classes.line_9}`}></div>
        <div className={`${classes.line_10}`}></div>

        <div className={`${classes.contact_text}`}>
          <div>
            <h2 className={classes.black_color}>{languageTekst.contact_heading[1]}<br />{languageTekst.contact_heading[2]}</h2>
            <NavLink to={`/work/${language}`}>
              <div className={classes.request_offer_button} onMouseLeave={ev => ev.target['childNodes'][0].textContent = languageTekst.email_text[0]}
                   onMouseOver={ev => ev.target['childNodes'][0].textContent = languageTekst.email_text[1]}>
                {languageTekst.email_text[0]}

                <i className='bx bx-chevron-right'></i>
              </div>
            </NavLink>
          </div>

          <img alt={'proces rada'} src={'./images/luka.png'}></img>
        </div>

        <div className={classes.contact_map}>
          <div className={classes.contact_map_div}>
            {isLoaded && <Map />}
          </div>

          <div className={classes.contact_text_2}>
            <div>
              <p>Maksimovic Solution</p>
              <img alt={'proces rada'} src={'./images/SNM logo 3 1.png'}></img>
            </div>
            <div>
              <div>
                <p>Email:</p>
                <p>stefan@snmsolution.ch</p>
              </div>
              <div>
                <p>UID:</p>
                <p>CHE-244.748.458</p>
              </div>
              <div>
                <p>{languageTekst.contact_info[0]}:</p>
                <p>+41 79 935 88 14</p>
              </div>
              <div>
                <p>{languageTekst.contact_info[1]}:</p>
                <p>Berneckerstrasse 215<br />
                  9435 Heerbrugg<br />
                  Schweiz</p>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.copyright}>
          <p>{languageTekst.contact_info[2]}</p>
        </div>
      </section>
    </>
  );
};

export default HomePage;