import classes from "./MailSent.module.css";
import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

export const BackDrop = () => {
  return (
    <div className={classes.backdrop}></div>
  )
}

const Modal = ({tekst, closeModal, result}) => {
  const [modalClasses, setModalClasses] = useState(`${classes.modal} ${classes.hidden}`);

  useEffect(() => {
    setModalClasses(`${classes.modal}`);
  }, [])

  return (
    <div className={modalClasses}>
      <i className='bx bxs-check-circle'></i>
      <h1>
        {result === 'success' ? tekst.mail[0] : tekst.mail[1]}
      </h1>

      <div className={classes.buttons}>
        <div className={classes.send_another} onClick={closeModal}>
          {tekst.mail[2].toUpperCase()}
        </div>

        <NavLink to={`/`}>
          <div className={`${classes.send_another} ${classes.go_back}`}>
            {tekst.mail[3].toUpperCase()}
          </div>
        </NavLink>
      </div>
    </div>
  )
}

const MailSent = ({closeModal, language, tekst, result}) => {
  return (
    <>
      {ReactDOM.createPortal(<BackDrop closeModal={closeModal}/>, document.getElementById('overlays'))}
      {ReactDOM.createPortal(<Modal result={result} closeModal={closeModal} language={language}
                                    tekst={tekst}/>, document.getElementById('overlays'))}
    </>
  )
}

export default MailSent;