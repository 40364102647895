import React, {useEffect, useRef, useState} from 'react';
import classes from "./HomePage.module.css";
import Input from "../components/UI/Input";
import TextArea from "../components/UI/TextArea";
import {english, german} from "./languages";
import {Link, NavLink} from "react-router-dom";
import MailSent from "../components/UI/Modals/MailSent";

const initValues = {
  number: '',
  delivery: '',
  name: '',
  reference: '',
  email: '',
  company: '',
  address: '',
  city: '',
  phone: '',
  note: ''
}

const Work = ({language}) => {
  const languageTekst = language === 'ger' ? german : english;

  const uploadField = useRef();

  const [pieces, setPieces] = useState('0'), [price, setPrice] = useState('0'),
  [currency, setCurrency] = useState('CHF'), [uploadedFiles, setUploadedFiles] = useState([]);

  const [values, setValues] = useState(initValues);

  const [showResultModal, setShowResultModal] = useState('');

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    scrollToTop();
  }, []);

  const html = `${values.number ? `<p>Zeichnungsnummer: ${values.number}</p>` : ''}${values.delivery ? `<p>Liefertermin: ${values.delivery}</p>` : ''}
    <p>Stückzahl: ${pieces}</p>${values.note ? `<p>Bemerkungen: ${values.note}</p>` : ''}<p>Zielpreis pro Stück: ${price} ${currency}</p>${values.name ? `<p>Name: ${values.name}</p>` : ''}
    ${values.reference ? `<p>Ihre Referenz: ${values.reference}</p>` : ''}${values.email ? `<p>E-mail: ${values.email}</p>` : ''}${values.company ? `<p>Firma: ${values.company}</p>` : ''}
    ${values.address ? `<p>Adresse: ${values.address}</p>` : ''}${values.city ? `<p>PLZ und Ort: ${values.city}</p>` : ''}${values.phone ? `<p>Telefon: ${values.phone}</p>` : ''}`;

  function sendEmail() {
    window['Email'].send({
      SecureToken: "e4d20f21-e378-4e8a-bed1-6ccb25c9ed64",
      To: 'stefan@snmsolution.ch',
      From: "info@snmsolution.ch",
      Subject: "Neue Offerte",
      Body: html,
      Attachments : uploadedFiles.map(file => {
        return {name: file.name, data: file.path};
      })
    })
      .then(function (message) {
        console.log(message);
        if (message === 'OK') setShowResultModal('success');
        else setShowResultModal('failure');
      });
  }

  const inputChangeHandler = ev => {
    const tempValues = {...values};
    tempValues[ev.target.name] = ev.target.value;

    setValues(tempValues);
  }

  const onUploadHandler = event => {
    const allFiles = [...uploadedFiles];

    for (const file of event.target.files) {
      if (allFiles.findIndex(fajl => fajl.name === file.name) !== -1) continue;

      const newFile = {name: file.name, size: file.size}
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        newFile.path = reader.result;
      }
      allFiles.push(newFile);
    }

    setUploadedFiles(allFiles);
  }

  const deleteFile = name => {
    const allFiles = [...uploadedFiles];

    allFiles.splice(allFiles.findIndex(file => file.name === name), 1);

    setUploadedFiles(allFiles);
  }

  const clearForm = () => {
    setShowResultModal('');
    setValues(initValues);
    setUploadedFiles([]);
    scrollToTop();
  }

  const submitHandler = ev => {
    ev.preventDefault();
    sendEmail();
  }

  return (
    <>
      {showResultModal && <MailSent result={showResultModal} closeModal={clearForm} language={language} tekst={languageTekst} />}

      <section className={classes.section_6}>

        <NavLink to={'/'} className={classes.back_arrow}>
          <i className='bx bx-arrow-back'></i>
        </NavLink>

        <div className={`${classes.lines} ${classes.line_11}`}></div>
        <div className={`${classes.lines} ${classes.line_three}`}></div>

        <div className={`${classes.h2_title} ${classes.h2_title_alt}`}>
          <h2>{languageTekst.work_heading[0]}<br />{languageTekst.work_heading[1]}</h2>

          <Link to={'/'}>
            <img alt={'proces rada'} src={'../images/SNM logo 3 1.png'}></img>
          </Link>
        </div>

        <form onSubmit={submitHandler}>
          <div className={classes.work_page_text}>
            <div>
              <h2>{languageTekst.work_heading[2]}</h2>
              <p>
                {languageTekst.work_text[0]}
              </p>
            </div>

            <div className={classes.work_input}>
              <div>
                <div>
                  <Input name={"number"} text={languageTekst.input_fields[0]} value={values.number} onChange={inputChangeHandler} type={"text"} />

                  <Input name={"delivery"} text={languageTekst.input_fields[2]} value={values.delivery} onChange={inputChangeHandler} type={"text"} />

                  <TextArea name={"note"} text={languageTekst.input_fields[4]} value={values.note} onChange={inputChangeHandler} height={"170px"} />
                </div>

                <div>
                  <div className={classes.upload} onClick={() => uploadField.current['click']()}>
                    <i className='bx bx-plus'></i>
                    <p>
                      {languageTekst.input_fields[1]}
                    </p>
                    <input ref={uploadField} id={'file-upload'} type={"file"} multiple={true} onChange={onUploadHandler}/>
                  </div>

                  <div className={classes.uploaded}>
                    {uploadedFiles.map(file => <div key={file.name}>
                      <div>
                        <i className='bx bxs-file-blank'></i>
                        <div>
                          <p>{file.name} - Hochgeladen</p>
                          <p>{file.size > 1000000 ? `${(file.size / 1000000).toFixed(2)} MB` : `${(file.size / 1000).toFixed(2)} KB`}</p>
                        </div>
                      </div>
                      <i className='bx bxs-trash-alt' onClick={() => deleteFile(file.name)}></i>
                    </div>)}

                  </div>
                </div>
              </div>

              <div>
                <div className={classes.select_pieces}>
                  <p>{languageTekst.input_fields[3]}:</p>
                  <div>
                    <div>
                      <i className='bx bx-minus' onClick={() => setPieces(prevState => `${+prevState - 1}`)}></i>
                    </div>
                    <input value={pieces} name={"pieces"} type={"text"} style={{width: `${((pieces.length - 2) * 15) + 70}px`, maxWidth: '200px'}} onChange={ev => setPieces(ev.target.value)} />
                    <div>
                      <i className='bx bx-plus' onClick={() => setPieces(prevState => `${+prevState + 1}`)}></i>
                    </div>
                  </div>
                </div>

                <div className={classes.select_pieces}>
                  <p>{languageTekst.input_fields[5]}:</p>
                  <div>
                    <div>
                      <i className='bx bx-minus' onClick={() => setPrice(prevState => `${+prevState - 1}`)}></i>
                    </div>
                    <input value={price} type={"text"} style={{width: `${((price.length - 2) * 15) + 70}px`, maxWidth: '200px'}} onChange={ev => setPrice(ev.target.value)} />
                    <div>
                      <i className='bx bx-plus' onClick={() => setPrice(prevState => `${+prevState + 1}`)}></i>
                    </div>
                    <div className={classes.currency}
                         onClick={() => setCurrency(prevState => prevState === 'CHF' ? 'EUR' : 'CHF')}>{currency}</div>
                  </div>
                </div>
              </div>

            </div>

            <div className={classes.line_12}></div>

            <div>
              <div>
                <Input name={"name"} text={languageTekst.input_fields[6]} value={values.name} onChange={inputChangeHandler} width={"100%"} type={"text"} />
              </div>

              <div>
                <Input name={"reference"} text={languageTekst.input_fields[7]} value={values.reference} onChange={inputChangeHandler} width={"100%"} type={"text"} />
              </div>

              <div>
                <Input name={"email"} text={languageTekst.input_fields[8]} value={values.email} onChange={inputChangeHandler} width={"100%"} type={"email"} required={true} />
              </div>

              <div>
                <Input name={"company"} text={languageTekst.input_fields[9]} value={values.company} onChange={inputChangeHandler} width={"100%"} type={"text"} />
              </div>

              <div>
                <Input name={"address"} text={languageTekst.input_fields[10]} value={values.address} onChange={inputChangeHandler} width={"100%"} type={"text"} />
                <Input name={"city"} text={languageTekst.input_fields[11]} value={values.city} onChange={inputChangeHandler} width={"100%"} type={"text"} />
              </div>

              <div>
                <Input name={"phone"} text={languageTekst.input_fields[12]} value={values.phone} onChange={inputChangeHandler} width={"100%"} type={"text"} />
              </div>
            </div>
            <div className={classes.action_button}>
              <button className={`${classes.request_offer_button} ${classes.request_offer_button_alt}`}>
                {languageTekst.send}
              </button>
            </div>
          </div>
        </form>

        <div className={`${classes.mountains} ${classes.mountains_2}`}></div>
        <div className={`${classes.gradient_2} ${classes.gradient_3}`}></div>
      </section>
    </>
  );
};

export default Work;